import { ColumnTwoWithTop } from '@graphcommerce/next-ui'
import { RichTextHeadingStrongAlternateColor } from '../RichText/RichTextHeadingStrongAlternateColor'
import { RowPageListFragment } from './RowPageList.gql'
import { RowPageListPage } from './RowPageListPage'

type RowPageListProps = RowPageListFragment

export function RowPageList(props: RowPageListProps) {
  const { rowPageListTitle, rowPageListPages } = props

  const top = rowPageListTitle?.raw ? (
    <RichTextHeadingStrongAlternateColor {...rowPageListTitle} />
  ) : (
    ''
  )
  const left = <RowPageListPage {...rowPageListPages[0]} />
  const right = <RowPageListPage {...rowPageListPages[1]} />

  return (
    <ColumnTwoWithTop
      sx={[
        (theme) => ({
          gap: { lg: `${theme.spacings.sm} ${theme.spacings.lg}` },
          gridTemplateColumns: '1fr',
          gridTemplateAreas: `
          "top  ."
          "left"
          "right"
          `,
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 1fr',
            gridTemplateAreas: `
            "top  ."
            "left right"
            `,
          },
          a: { mb: { xs: theme.spacings.lg, sm: 0 } },
          img: { mb: theme.spacings.xxs },
        }),
      ]}
      top={top}
      left={left}
      right={right}
    />
  )
}
