import { Asset, RichText } from '@graphcommerce/graphcms-ui'
import { RichText as RichTextType } from '@graphcommerce/graphql-mesh'
import { Row, responsiveVal } from '@graphcommerce/next-ui'
import { useTheme, Box, Typography, Link } from '@mui/material'
import renderers from '../GraphCMS/RichText/renderers'
import { RowVacancyFragment } from './RowVacancy.gql'

export function RowVacancy(
  props: RowVacancyFragment & { richTextOne?: Omit<RichTextType, 'raw'> },
) {
  const { vacancyType, date, hours, richTextOne, locations } = props
  const theme = useTheme()
  const splittedDate = date.split('T')[0].split('-')

  return (
    <Row maxWidth='md' sx={{mb: theme.spacings.md}}>
        <Box sx={{ margin: '20px auto' }}>
          <Typography component='h1' variant='h1'>
            {vacancyType?.title}
          </Typography>
          {date
            ? `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]} ${
                hours ? `| ${hours}` : ''
              }`
            : ''}{' '}
          ·{' '}
          {locations?.map((location) => (
            <Link href={`/${location.url}`} underline='none'>
              {location.name}
            </Link>
          ))}
        </Box>

        {vacancyType?.image && (
          <Box
            sx={{
              display: 'grid',
              margin: '20px auto',
              overflow: 'hidden',
              height: '100%',
              width: '100%',
              borderRadius: responsiveVal(
                theme.shape.borderRadius * 2,
                theme.shape.borderRadius * 3,
              ),
              '& img': {
                height: '100% !important',
                objectFit: 'cover',
              },
              '& p': {
                alignSelf: 'center',
                justifySelf: 'center',
              },
              background: theme.palette.background.paper,
            }}
          >
            <Asset
              asset={vacancyType.image}
              sizes={{ 0: '48vw', 711: '30vw', [theme.breakpoints.values.lg]: '330px' }}
            />
          </Box>
        )}

        {vacancyType?.description && (
          <Box>
            <RichText {...vacancyType?.description} {...richTextOne} sxRenderer={renderers} />
          </Box>
        )}
    </Row>
  )
}
