import { Row } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { ProductPageCustomAttributeFieldsFragment } from '../../../../graphql/ProductPageCustomAttributeFields.gql'
import { RowProductFragment } from '../RowProduct.gql'
import { excludedAttributes } from '../constants/specsConstants'

type SpecsProps = RowProductFragment & Partial<ProductPageCustomAttributeFieldsFragment>

export function Specs(props: SpecsProps) {
  const { custom_attributes } = props

  // filter excludedAttributes from custom_attributes
  const attributes =
    custom_attributes?.filter(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      (attribute) => !excludedAttributes.includes(attribute?.attribute_metadata?.code ?? ''),
    ) ?? []

  return (
    <Row
      sx={{
        typography: 'subtitle1',
        color: 'text.secondary',
        maxWidth: '820px',
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.grey[300],
            paddingBottom: 1,
            typography: 'overline',
          })}
        >
          <Trans id='Product Details' />
        </Box>

        <TableContainer>
          <Table>
            <TableBody>
              {attributes.map((attribute) => (
                <TableRow
                  sx={{
                    '& .MuiTableCell-root': {
                      borderColor: 'transparent',
                      paddingX: 0,
                      paddingBottom: 0,
                    },
                  }}
                  key={attribute?.attribute_metadata?.label}
                >
                  <TableCell>
                    <Typography variant='body1'>{attribute?.attribute_metadata?.label}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body1'>
                      {attribute?.entered_attribute_value?.value}
                      {attribute?.selected_attribute_options?.attribute_option
                        ?.map((option) => option?.label)
                        .join(', ')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Row>
  )
}
