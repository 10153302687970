import { Asset } from '@graphcommerce/graphcms-ui'
import { Link, Typography } from '@mui/material'
import { RowPageListFragment } from './RowPageList.gql'

export function RowPageListPage(props: RowPageListFragment['rowPageListPages'][0]) {
  const { title, metaTitle, url, asset, content } = props

  let summary = ''
  content.forEach((row) => {
    if (row.__typename !== 'RowBlogContent') return
    if (row.content?.text) summary += row.content.text
  })

  summary = summary.replace(/\\n/g, '')

  return (
    <Link
      key={title}
      href={url.startsWith('/') ? url : `/${url}`}
      underline='none'
      sx={{
        display: 'block',
        color: 'inherit',
      }}
    >
      {asset && <Asset asset={asset} />}
      <Typography component='h3' variant='h4' sx={(theme) => ({ marginBottom: theme.spacings.xs })}>
        {title ?? metaTitle}
      </Typography>
      <Typography
        variant='body1'
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            lineHeight: '37px',
          },
        })}
      >
        {summary.length > 150 ? `${summary.substring(0, 200)}...` : summary}
      </Typography>
    </Link>
  )
}
