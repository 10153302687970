import { Container } from '@mui/material'
import { RowHeroBannerFragment } from '../../RowHeroBanner.gql'
import { LongHero } from './LongHero'
import { ShortHero } from './ShortHero'

export function Split(props: RowHeroBannerFragment) {
  const { copy, heroAsset, heroTitle, pageLinks, secondBanner } = props

  return (
    <Container
      sx={(theme) => ({
        background: theme.palette.background.default,
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: '1fr 1fr',
          md: '3fr minmax(400px, 1fr)',
          lg: '3fr 450px',
        },
        rowGap: theme.spacings.md,
        columnGap: theme.spacings.md,
        maxWidth: { xs: 'unset' },
        '& .HeroBanner-root': {
          paddingX: 0,
        },
      })}
    >
      <LongHero copy={copy} heroAsset={heroAsset} pageLinks={pageLinks} heroTitle={heroTitle} />
      <ShortHero secondBanner={secondBanner} />
    </Container>
  )
}
