import { Container, Interpolation, Theme } from '@mui/material'
import Script from 'next/script'
import { ClassAttributes, HTMLAttributes, useCallback, useEffect } from 'react'
import { RowFormsAppFragment } from './RowFormsApp.gql'

export function RowFormsApp(props: RowFormsAppFragment) {
  const { identity } = props

  // eslint-disable-next-line new-cap
  const init = useCallback(() => new globalThis.formsapp(identity), [identity])

  const scriptProps = { formsappid: identity } as HTMLAttributes<HTMLDivElement>

  useEffect(() => {
    if (globalThis.formsapp && !document.querySelector('iframe[src*="forms.app"]')) {
      // eslint-disable-next-line new-cap, no-new
      new globalThis.formsapp(identity)
    }
  })

  return (
    <Container>
      <div {...scriptProps} />
      <Script
        src='https://my.forms.app/static/iframe.js'
        strategy='afterInteractive'
        onLoad={init}
      />
    </Container>
  )
}
