import {
  AddProductsToCartForm,
  ProductListItem,
  UpsellProductsFragment,
} from '@graphcommerce/magento-product'
import {
  SidebarSlider,
  RenderType,
  responsiveVal,
  SidebarSliderProps,
} from '@graphcommerce/next-ui'
import { Typography } from '@mui/material'
import { productListRenderer } from '../../../ProductListItems/productListRenderer'
import { RichTextHeadingStrongAlternateColor } from '../../RichText/RichTextHeadingStrongAlternateColor'
import { RowProductFragment } from '../RowProduct.gql'

type UpsellsProps = RowProductFragment & UpsellProductsFragment & Pick<SidebarSliderProps, 'sx'>

const renderer = {
  ...productListRenderer,
  SimpleProduct: ProductListItem,
}

export function Upsells(props: UpsellsProps) {
  const { title, productCopy, upsell_products, sx } = props

  if (!upsell_products || upsell_products.length === 0) return null

  const sidebar = productCopy?.raw ? (
    <RichTextHeadingStrongAlternateColor {...productCopy} />
  ) : (
    <Typography variant='h2'>{title}</Typography>
  )

  return (
    <AddProductsToCartForm>
      <SidebarSlider
        sx={[
          {
            '& .SidebarSlider-scroller': {
              gridAutoColumns: responsiveVal(200, 300),
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        sidebar={sidebar}
      >
        {upsell_products?.map((item, index) =>
          item ? (
            <RenderType
              key={item.uid ?? ''}
              renderer={renderer}
              sizes={responsiveVal(200, 300)}
              index={index}
              // titleComponent='h3'
              {...item}
            />
          ) : null,
        )}
      </SidebarSlider>
    </AddProductsToCartForm>
  )
}
