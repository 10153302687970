import { responsiveVal } from '@graphcommerce/next-ui'
import { styled, SxProps, Theme } from '@mui/material'
import { m, useScroll, useTransform } from 'framer-motion'
import { useState } from 'react'

const MotionDiv = styled(m.div)({})

type ScrollIndicatorProps = { sx?: SxProps<Theme> }

export function ScrollIndicator(props: ScrollIndicatorProps) {
  const { sx = [] } = props
  const { scrollY } = useScroll()
  const maxScrollY = 200
  const [animate, setAnimate] = useState<boolean>(true)

  const opacity = useTransform(scrollY, (scrollYProgress: number) => {
    if (!animate) return 0
    if (scrollYProgress >= maxScrollY) {
      setAnimate(false)
      return 0
    }
    return 1.0 - scrollYProgress / maxScrollY
  })

  return (
    <MotionDiv
      style={{ opacity }}
      sx={[
        (theme) => ({
          position: 'absolute',
          margin: '0 auto',
          left: 0,
          right: 0,
          bottom: responsiveVal(16, 72),
          width: 21,
          height: 35,
          pointerEvents: 'none',
          borderRadius: 25,
          boxShadow: `inset 0 0 0 2px ${theme.palette.primary.contrastText}`,
          '&:before': {
            position: 'absolute',
            left: '50%',
            content: '""',
            width: 5,
            height: 5,
            background: theme.palette.primary.contrastText,
            marginLeft: '-3px',
            top: 5,
            borderRadius: '100%',
            animationDuration: '1.5s',
            animationIterationCount: 'infinite',
            animationName: 'scroll',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  )
}
