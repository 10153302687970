import { RichText } from '@graphcommerce/graphcms-ui'
import { IconBlock, IconBlocks, iconChat, iconEmail, IconSvg } from '@graphcommerce/next-ui'
import iconLegacyPhone from '@graphcommerce/next-ui/icons/phone.svg'
import renderers from '../RichText/renderers'
import { RowServiceOptionsFragment } from './RowServiceOptions.gql'

type RowServiceOptionsProps = RowServiceOptionsFragment

export function RowServiceOptions(props: RowServiceOptionsProps) {
  const { title, serviceOptions } = props

  return (
    <IconBlocks title={title}>
      {serviceOptions.map((serviceOption) => {
        const iconTitle = serviceOption.title.toLowerCase()

        return (
          <IconBlock
            key={serviceOption.title}
            href={serviceOption.url}
            title={serviceOption.title}
            icon={
              <>
                {iconTitle === 'e-mail' && <IconSvg src={iconEmail} size='large' />}
                {iconTitle === 'telefoon' && <IconSvg src={iconLegacyPhone} size='large' />}
                {iconTitle === 'chat' && <IconSvg src={iconChat} size='large' />}
              </>
            }
          >
            {serviceOption.description ? (
              <RichText {...serviceOption.description} sxRenderer={renderers} />
            ) : undefined}
          </IconBlock>
        )
      })}
    </IconBlocks>
  )
}
