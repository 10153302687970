import { RowHeroBannerFragment } from './RowHeroBanner.gql'
import { Full, Split } from './variant'

type VariantRenderer = Record<
  NonNullable<RowHeroBannerFragment['heroVariant']>,
  React.VFC<RowHeroBannerFragment>
>

type RowHeroBannerProps = RowHeroBannerFragment & {
  renderer?: Partial<VariantRenderer>
}

const defaultRenderer: Partial<VariantRenderer> = {
  Full,
  Split,
}

export function RowHeroBanner(props: RowHeroBannerProps) {
  const { renderer, heroVariant, ...RowHeroBannerProps } = props
  const mergedRenderer = { ...defaultRenderer, ...renderer } as VariantRenderer

  if (!heroVariant) return null

  const RenderType =
    mergedRenderer?.[heroVariant] ??
    (() => {
      if (process.env.NODE_ENV !== 'production') return <>renderer for {heroVariant} not found</>
      return null
    })

  return <RenderType {...RowHeroBannerProps} />
}
