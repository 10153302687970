import { AddProductsToCartForm, RelatedProductsFragment } from '@graphcommerce/magento-product'
import {
  SidebarSlider,
  RenderType,
  responsiveVal,
  SidebarSliderProps,
} from '@graphcommerce/next-ui'
import { Typography } from '@mui/material'
import { crosssellsRender } from '../../../ProductListItems/Crosssells/CrosssellsRender'
import { useCustomerDataList } from '../../../useCustomerProductData/useCustomerDataList'
import { RichTextHeadingStrongAlternateColor } from '../../RichText/RichTextHeadingStrongAlternateColor'
import { RowProductFragment } from '../RowProduct.gql'

type RelatedProps = RowProductFragment & RelatedProductsFragment & Pick<SidebarSliderProps, 'sx'>

export function Related(props: RelatedProps) {
  const { title, productCopy, related_products: r, sx } = props
  const related_products = useCustomerDataList({ items: r }).items
  if (!related_products || related_products.length === 0) return null

  const sidebar = productCopy?.raw ? (
    <RichTextHeadingStrongAlternateColor
      {...productCopy}
      sxRenderer={{
        'heading-two': {
          marginTop: 0,
          marginBottom: 0,
        },
      }}
    />
  ) : (
    <Typography variant='h2'>{title}</Typography>
  )

  return (
    <AddProductsToCartForm>
      <SidebarSlider
        sx={[
          {
            '& .SidebarSlider-scroller': {
              gridAutoColumns: responsiveVal(200, 300),
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        sidebar={sidebar}
      >
        {related_products
          .filter((item) => item?.stock_status === 'IN_STOCK')
          ?.map((item, index) =>
            item ? (
              <RenderType
                key={item.uid ?? ''}
                renderer={crosssellsRender}
                sizes={responsiveVal(200, 300)}
                index={index}
                {...item}
              />
            ) : null,
          )}
      </SidebarSlider>
    </AddProductsToCartForm>
  )
}
