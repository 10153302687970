import { RichText } from '@graphcommerce/graphcms-ui'
import { ColumnTwo } from '@graphcommerce/next-ui'
import renderers from '../RichText/renderers'
import { RowColumnTwoFragment } from './RowColumnTwo.gql'

export function RowColumnTwo(props: RowColumnTwoFragment) {
  const { colOne, colTwo } = props

  return (
    <ColumnTwo
      sx={(theme) => ({ mb: theme.spacings.lg })}
      colOneContent={<RichText {...colOne} sxRenderer={renderers} />}
      colTwoContent={<RichText {...colTwo} sxRenderer={renderers} />}
    />
  )
}
