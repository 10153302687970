import { Asset } from '@graphcommerce/graphcms-ui'
import { LinkOrButton, responsiveVal } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Container, Link, Typography, useTheme } from '@mui/material'
import { RowVacancyListFragment } from './RowVacancyList.gql'

export function RowVacancyList(props: RowVacancyListFragment) {
  const { rowVacancies, listTitle, link } = props
  const theme = useTheme()
  return (
    <Container key={listTitle} maxWidth='md'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: responsiveVal(0, 25),
          marginRight: responsiveVal(0, 25),
        }}
      >
        <Typography component='h3' variant='h3' gutterBottom>
          {listTitle}
        </Typography>
        {link && (
          <LinkOrButton href={`/${link}`} color='primary'>
            <Trans id='View all' />
          </LinkOrButton>
        )}
      </Box>
      {rowVacancies.length > 0 ? (
        <Container
          sx={{
            display: 'grid',
            gap: theme.spacings.md,
            paddingX: 0,
            gridTemplateColumns: `repeat(auto-fill, minmax(${responsiveVal(150, 285)}, 1fr))`,
          }}
        >
          {rowVacancies.map((vacancy) => (
            <Box
              key={vacancy.id}
              sx={{
                display: 'grid',
                gridTemplateRows: `${responsiveVal(140, 220)} auto auto`,
                alignContent: 'start',
                color: theme.palette.text.primary,
                gap: theme.spacings.xxs,
              }}
            >
              <Link href={`/${vacancy.pages[0].url}`} color='inherit' underline='hover'>
                <Box
                  sx={{
                    display: 'grid',
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    borderRadius: responsiveVal(
                      theme.shape.borderRadius * 2,
                      theme.shape.borderRadius * 3,
                    ),
                    '& img': {
                      height: '100% !important',
                      objectFit: 'cover',
                    },
                    '& p': {
                      alignSelf: 'center',
                      justifySelf: 'center',
                    },
                    background: theme.palette.background.paper,
                  }}
                >
                  {vacancy?.vacancyType?.image && (
                    <Asset
                      asset={vacancy?.vacancyType?.image}
                      sizes={{
                        0: '48vw',
                        711: '30vw',
                        [theme.breakpoints.values.lg]: '330px',
                      }}
                    />
                  )}
                </Box>
              </Link>

              <Link href={`/${vacancy.pages[0].url}`} color='inherit' underline='hover'>
                <Typography component='h2' variant='h4' sx={{ marginBottom: 0 }}>
                  {vacancy?.vacancyType?.title}
                </Typography>
                {`${vacancy?.hours}`}
              </Link>
            </Box>
          ))}
        </Container>
      ) : (
        <p>Er zijn momenteel geen openstaande vacatures</p>
      )}
    </Container>
  )
}
