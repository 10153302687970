import { ProductListItemsBase, ProductItemsGridProps } from '@graphcommerce/magento-product'
import { productListRenderer } from './productListRenderer'

export type ProductListItemsProps = Omit<ProductItemsGridProps, 'renderers'>

export function ProductListItems(props: ProductListItemsProps) {
  const { pageNumber } = props
  return (
    <ProductListItemsBase
      pageNumber={pageNumber}
      renderers={productListRenderer}
      size='normal'
      sx={(theme) => ({
        '&.sizeNormal': {
          gridTemplateColumns: { xs: `1fr`, md: `1fr`, lg: `1fr` },
          '& img': {
            mixBlendMode: 'multiply',
          },
          '& form:last-of-type': {
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          },
        },
      })}
      {...props}
    />
  )
}
