import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { Fab, iconArrowForward } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { HeroBanner } from '../../HeroBanner'
import { RowHeroBannerFragment } from '../../RowHeroBanner.gql'

type ShortHeroProps = Pick<RowHeroBannerFragment, 'secondBanner'>

export function ShortHero(props: ShortHeroProps) {
  const { secondBanner } = props
  const bannerUrl = secondBanner?.pageLinks?.[0].url ?? ''
  return (
    <HeroBanner
      bannerUrl={bannerUrl}
      pageLinks={null}
      sx={(theme) => ({
        mb: 0,
        backgroundColor: secondBanner?.color?.hex || theme.palette.primary.main,
        position: 'relative',
        overflow: 'hidden',
        '& .HeroBanner-copy': {
          ml: theme.spacings.xxs,
          textAlign: 'left',
          justifyItems: 'flex-start',
          gap: theme.spacings.sm,
        },
      })}
    >
      {secondBanner?.copy && (
        <Box
          component='span'
          sx={(theme) => ({
            '& .MuiTypography-root': {
              color: theme.palette.primary.contrastText,
              textDecoration: 'none',
            },
            mb: { xs: theme.spacings.xxl, md: 0 },
          })}
        >
          <RichText
            {...secondBanner.copy}
            sxRenderer={{
              paragraph: {
                typography: 'h5',
                fontWeight: 'bold',
                width: '80%',
              },
            }}
          />

          {bannerUrl && (
            <Fab
              sx={(theme) => ({
                backgroundColor: theme.palette.text.primary,
                '& use': { strokeWidth: '5px', strokeLinecap: 'round', strokeLinejoin: 'round' },
              })}
              component='span'
              icon={iconArrowForward}
              aria-label='aanbieding bekijken'
            />
          )}
        </Box>
      )}
      {secondBanner?.asset?.url && (
        <Box
          component='span'
          sx={{
            position: 'absolute',
            right: 0,
            bottom: { xs: 'auto', sm: 0 },
            width: '80%',
            '& img': { verticalAlign: 'bottom' },
          }}
        >
          <Image
            src={secondBanner.asset.url}
            alt={secondBanner.asset.alt ?? ''}
            aria-label={secondBanner.asset.alt ?? ''}
            width={secondBanner.asset.width ?? 0}
            height={secondBanner.asset.height ?? 0}
            unoptimized={secondBanner.asset.mimeType === 'image/svg+xml'}
          />
        </Box>
      )}
    </HeroBanner>
  )
}
