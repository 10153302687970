import { Button, Row } from '@graphcommerce/next-ui'
import { RowButtonFragment } from './RowButton.gql'

export function RowButton(props: RowButtonFragment) {
  const { title, url, color } = props

  const openInNewTab = () => {
    window.open(`https://${url}`, '_blank', 'noopener,noreferrer')
  }
  return (
    <Row sx={(theme) => ({ textAlign: 'center', mb: theme.spacings.lg })}>
      {url.includes('.') ? (
        <Button color={color} variant='pill' size='large' onClick={openInNewTab}>
          {title}
        </Button>
      ) : (
        <Button href={`/${url}`} color={color} variant='pill' size='large'>
          {title}
        </Button>
      )}
    </Row>
  )
}
