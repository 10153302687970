import { RichText } from '@graphcommerce/graphcms-ui'
import { Button, iconArrowForward, Row, IconSvg } from '@graphcommerce/next-ui'
import { alpha, Box } from '@mui/material'
import { ScrollIndicator } from '../../../ScrollIndicator'
import { RichTextHeadingStrongAlternateColor } from '../../RichText/RichTextHeadingStrongAlternateColor'
import renderers from '../../RichText/renderers'
import { PromotionSlider } from '../PromotionSlider'
import { RowHeroBannerFragment } from '../RowHeroBanner.gql'

export function Full(props: RowHeroBannerFragment) {
  const { copy, heroAsset, pageLinks, heroTitle, promotions } = props

  return (
    <Row
      maxWidth={false}
      sx={(theme) => ({
        position: 'relative',
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        mb: theme.spacings.xl,
      })}
    >
      <Box
        sx={(theme) => ({
          flexDirection: 'column-reverse',
          gap: { xs: theme.spacings.lg, md: 0 },
          display: { xs: 'flex', smd: 'grid' },
          maxWidth: { xs: 400, smd: 2000 },
          gridTemplateColumns: '1fr 1.27fr',
          alignItems: 'center',
          pt: theme.spacings.sm,
        })}
      >
        <Box sx={(theme) => ({ px: { smd: theme.spacings.lg } })}>
          <Box sx={{ maxWidth: 634, textTransform: 'lowercase' }}>
            <RichTextHeadingStrongAlternateColor {...heroTitle} />
          </Box>
          <Box sx={{ maxWidth: 534, '& p': { typography: 'h6' } }}>
            <RichText {...copy} sxRenderer={renderers} />
          </Box>
          {pageLinks?.[0] ? (
            <Button
              href={pageLinks[0].url}
              component='a'
              target={pageLinks[0].openInNewWindow ? '_blank' : '_self'}
              variant='pill'
              color='primary'
              size='large'
              sx={(theme) => ({ mt: theme.spacings.md })}
            >
              {pageLinks[0].title}
            </Button>
          ) : null}
        </Box>
        <Box sx={{ height: 0, pt: '100%', position: 'relative', width: '100%' }}>
          <Box
            component='video'
            src={heroAsset.url}
            autoPlay
            muted
            loop
            playsInline
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              objectFit: 'cover',
              borderRadius: '50%',
              objectPosition: 'center',
            }}
          />
          {pageLinks?.[1] ? (
            <Button
              href={pageLinks[1].url}
              component='a'
              target={pageLinks[1].openInNewWindow ? '_blank' : '_self'}
              variant='pill'
              color='primary'
              size='medium'
              sx={(theme) => ({
                position: 'absolute',
                bottom: 50,
                left: '50%',
                transform: 'translateX(-50%)',
                textDecoration: 'underline',
                typography: theme.typography.subtitle1,
                background: alpha(theme.palette.background.paper, 0.4),
                '&:hover:not(.Mui-disabled)': {
                  textDecoration: 'underline',
                  background: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                },
              })}
              endIcon={
                <Box
                  sx={(theme) => ({
                    borderRadius: '50%',
                    background: theme.palette.primary.main,
                    marginLeft: theme.spacings.xxs,
                    padding: { xs: '4px', sm: '6px' },
                    '& > svg': {
                      width: { xs: 14, sm: 18 },
                      height: { xs: 14, sm: 18 },
                      margin: '1px',
                      display: 'block',
                    },
                  })}
                >
                  <IconSvg size='medium' src={iconArrowForward} />
                </Box>
              }
            >
              {pageLinks[1].title}
            </Button>
          ) : null}
          <PromotionSlider promotions={[...promotions]} />
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          height: `calc(100vh - ${theme.appShell.headerHeightMd})`,
          maxHeight: `100%`,
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <ScrollIndicator />
      </Box>
    </Row>
  )
}
