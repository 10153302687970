import { Asset } from '@graphcommerce/graphcms-ui'
import { Row, Button } from '@graphcommerce/next-ui'
import { Box, Container, Link, Typography, alpha } from '@mui/material'
import { RowLinksFragment } from '../RowLinks.gql'

export function ImagesThree(props: RowLinksFragment) {
  const { title, rowLinksCopy, pageLinks, ...containerProps } = props

  /** Slice the last characted of the title, if it is a period: true */
  const period = title.slice(-1) === '.' ? title.slice(-1) : undefined

  return (
    <Container
      maxWidth={false}
      sx={(theme) => ({
        background: alpha(theme.palette.primary.main, 0.1),
        py: theme.spacings.lg,
        mb: theme.spacings.lg,
      })}
      {...containerProps}
    >
      <Row
        maxWidth='lg'
        disableGutters
        sx={(theme) => ({ mb: theme.spacings.md, px: theme.page.horizontal })}
      >
        <Box>
          <Typography
            component='h2'
            variant='h2'
            sx={(theme) => ({
              mb: theme.spacings.md,
              '& > strong': { color: 'primary.main' },
            })}
          >
            {period ? (
              <>
                {title.slice(0, -1)}
                <strong>{period}</strong>
              </>
            ) : (
              title
            )}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gap: theme.spacings.lg,
            gridTemplateColumns: {
              xs: 'repeat(1, minmax(0, 1fr))',
              md: 'repeat(3, minmax(0, 1fr))',
            },
          })}
        >
          {pageLinks.map((link) => {
            if (!link.asset) return null

            return (
              <Box
                href={link.url}
                key={link.id}
                component={Link}
                sx={{ aspectRatio: '8 / 5', position: 'relative', lineHeight: 0 }}
              >
                <Button
                  component='span'
                  sx={(theme) => ({
                    zIndex: 10,
                    position: 'absolute',
                    left: theme.spacings.sm,
                    bottom: theme.spacings.sm,
                  })}
                  variant='pill'
                  color='primary'
                  size='medium'
                >
                  {link.title}
                </Button>
                <Asset asset={link.asset} sx={{ objectFit: 'cover' }} layout='fill' />
              </Box>
            )
          })}
        </Box>
      </Row>
    </Container>
  )
}
