import { PageLinkFragment } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { responsiveVal, SidebarSlider } from '@graphcommerce/next-ui'
import { alpha, Box, Link } from '@mui/material'
import { RichTextHeadingStrongAlternateColor } from '../RichText/RichTextHeadingStrongAlternateColor'
import { RowSwipeablePageLinksFragment } from './RowSwipeablePageLinks.gql'

type RowSwipeablePageLinksProps = RowSwipeablePageLinksFragment

export function RowSwipeablePageLinks(props: RowSwipeablePageLinksProps) {
  const { rowSwipableTitle, items } = props

  if (!items || items.length === 0) return null

  return (
    <SidebarSlider
      sx={(theme) => ({
        background: alpha(theme.palette.primary.main, 0.1),
        py: theme.spacings.xxl,
        pl: theme.page.horizontal,
        gap: theme.spacings.sm,
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: '1fr',
        },

        '& .SidebarSlider-scroller': {
          gridAutoColumns: `100%`,
          gap: theme.spacings.lg,
          [theme.breakpoints.up('sm')]: {
            gridAutoColumns: `minmax(${responsiveVal(180, 600)}, 60vh)`,
          },
        },
        '& .SidebarSlider-sliderButtons': {
          '& .MuiFab-root': {
            background: theme.palette.primary.main,
            '&:hover': {
              background: theme.palette.primary.dark,
            },
            [theme.breakpoints.down('sm')]: {
              width: 36,
              height: 36,
            },
          },
          '& svg': {
            stroke: theme.palette.primary.contrastText,
            width: 30,
            height: 30,
            [theme.breakpoints.down('sm')]: {
              width: 20,
              height: 20,
            },
          },
        },
        '& .SidebarSlider-sidebar': {
          padding: 0,
          // todo: make the counter optional in GraphCommerce
          '& > div:last-of-type': {
            display: 'none',
          },
        },

        '& .SidebarSlider-centerLeft, & .SidebarSlider-centerRight': {
          top: `calc(50% - 120px)`,
          [theme.breakpoints.down('sm')]: {
            top: `calc(50% - 60px)`,
            '& button': {
              display: 'flex',
            },
          },
        },
      })}
      sidebar={
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              maxWidth: '55%',
            },
            '& h1, & h2, & h3, & h4': {
              typography: theme.typography.h1,
              maxWidth: 300,
            },
          })}
        >
          <RichTextHeadingStrongAlternateColor {...rowSwipableTitle} />
        </Box>
      }
    >
      {items?.map((pageLink: PageLinkFragment) => (
        <div key={pageLink.identity}>
          <div>
            <Link
              href={pageLink.url}
              color='inherit'
              underline='none'
              target={pageLink.openInNewWindow ? '_blank' : '_self'}
            >
              {pageLink?.asset && (
                <Image
                  layout='fill'
                  src={pageLink.asset.url}
                  sx={{ display: 'block' }}
                  sizes={responsiveVal(200, 800)}
                  alt={pageLink.asset.alt || ''}
                />
              )}
              {pageLink.description && (
                <Box
                  sx={(theme) => ({
                    maxWidth: { xs: '80%', md: 200 },
                    display: 'block',
                    pt: theme.spacings.sm,
                    fontSize: 'h3',
                  })}
                >
                  <RichTextHeadingStrongAlternateColor {...pageLink.description} />
                </Box>
              )}
            </Link>
          </div>
        </div>
      ))}
    </SidebarSlider>
  )
}
