import { ContentLinks } from '@graphcommerce/next-ui'
import { Link } from '@mui/material'
import { RowContentLinksFragment } from './RowContentLinks.gql'

export function RowContentLinks(props: RowContentLinksFragment) {
  const { title: mainTitle, contentLinks } = props

  return (
    <ContentLinks
      title={mainTitle}
      sx={{
        marginBottom: 0,
        paddingBottom: 5,
        '& .ContentLinks-title': {
          fontWeight: 600,
        },
      }}
    >
      {contentLinks.map(({ id, url, title }) => (
        <Link key={id} href={url} variant='body1' color='inherit' underline='hover'>
          {title}
        </Link>
      ))}
    </ContentLinks>
  )
}
