import { Asset, AssetFragment } from '@graphcommerce/graphcms-ui'
import { extendableComponent, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Theme, SxProps, Link } from '@mui/material'

import React from 'react'

export type HeroBannerProps = {
  pageLinks: React.ReactNode
  asset?: AssetFragment
  children: React.ReactNode
  sx?: SxProps<Theme>
  bannerUrl?: string
}

const compName = 'HeroBanner' as const
const parts = ['root', 'wrapper', 'copy', 'asset', 'animated', 'video'] as const
const { classes } = extendableComponent(compName, parts)

export function HeroBanner(props: HeroBannerProps) {
  const { pageLinks, asset, children, sx, bannerUrl = '' } = props

  return (
    <Box component={Link} href={bannerUrl} className={classes.root} sx={sx}>
      <Box
        component='span'
        className={classes.wrapper}
        sx={(theme) => ({
          display: 'grid',
          overflow: 'hidden',
          borderRadius: responsiveVal(theme.shape.borderRadius * 2, theme.shape.borderRadius * 3),
          isolation: 'isolate',
        })}
      >
        <Box
          component='span'
          className={classes.copy}
          sx={(theme) => ({
            gridArea: '1 / 1',
            zIndex: 1,
            display: 'grid',
            justifyItems: 'center',
            alignContent: 'center',
            textAlign: 'center',
            p: theme.spacings.md,
            color: theme.palette.secondary.contrastText,
          })}
        >
          {children}
          {pageLinks}
        </Box>
        <Box
          component='span'
          className={classes.asset}
          sx={{
            gridArea: '1 / 1',
            position: 'relative',
          }}
        >
          {asset && (
            <Box
              component='span'
              sx={{
                height: '100%',
                '& video, img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  aspectRatio: '10 / 1',
                },
              }}
            >
              <Asset asset={asset} loading='eager' />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
