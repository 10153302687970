import { IconSvg, Row } from '@graphcommerce/next-ui'
import iconDownload from '@graphcommerce/next-ui/icons/download.svg'
import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { RowLinksFragment } from '../RowLinks.gql'

export type DownloadsProps = RowLinksFragment

export function Downloads(props: DownloadsProps) {
  const { title, pageLinks } = props

  if (pageLinks.length === 0) return null

  return (
    <Row maxWidth='md'>
      <Box
        sx={[
          (theme) => ({
            position: 'relative',
            '&:focus': {
              outline: 'none',
            },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacings.sm,
            marginBottom: theme.spacings.xxs,
            paddingBottom: theme.spacings.xxs,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }),
        ]}
      >
        <Typography variant='overline' color='textSecondary' component='span'>
          {title}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'grid',
          columnGap: theme.spacings.sm,
        })}
      >
        {pageLinks?.map((link) => (
          <ListItemButton
            key={link.id}
            href={link.asset?.url ?? link.url}
            target={link.openInNewWindow ? '_blank' : '_self'}
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.divider}`,
              justifyContent: 'space-between',
            })}
          >
            <ListItemText> {link.title}</ListItemText>
            <ListItemIcon>{link.asset?.url && <IconSvg src={iconDownload} />}</ListItemIcon>
          </ListItemButton>
        ))}
      </Box>
    </Row>
  )
}
