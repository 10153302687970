// we dont want these attributes in our product detail tab
export const excludedAttributes = [
  'aantal_platen',
  'afwerking_steni',
  'artikelbreedte',
  'artikelcode',
  'artikeldikte',
  'artikelgroep1',
  'artikelgroep2',
  'artikelgroep3',
  'artikellengte',
  'artikeltype',
  'artikel_code_ib',
  'besteleenheid',
  'breedte_meeteenheid',
  'category_ids',
  'color',
  'cost',
  'country_of_manufacture',
  'custom_design',
  'custom_design_from',
  'custom_design_to',
  'custom_layout',
  'custom_layout_update_file',
  'delivery_time',
  'description',
  'dikte_meeteenheid',
  'gallery',
  'gift_message_available',
  'image',
  'lengte_meeteenheid',
  'manufacturer',
  'media_gallery',
  'meta_description',
  'meta_keyword',
  'meta_title',
  'msrp',
  'msrp_display_actual_price_type',
  'name',
  'news_from_date',
  'news_to_date',
  'options_container',
  'page_layout',
  'price',
  'price_type',
  'price_view',
  'quantity_and_stock_status',
  'shipment_type',
  'short_description',
  'sku',
  'sku_type',
  'small_image',
  'special_from_date',
  'special_price',
  'special_to_date',
  'status',
  'swatch_image',
  'tax_class_id',
  'test_kleur',
  'thumbnail',
  'tier_price',
  'url_key',
  'visibility',
  'weight',
  'weight_type',
  'ib_combined_description',
  'ib_uitgebreide_toelichting_1',
  'ib_uitgebreide_toelichting_2',
  'ib_toepassing',
  'ib_toepassing_2',
  'ib_verwerking',
  'ib_downloads',
  'hide_in_sitemap',
  'cutr_width_mm',
  'cutr_vargroup_name',
  'cutr_vargroup_id',
  'cutr_type',
  'cutr_thickness_um',
  'cutr_sawing_sku',
  'cutr_sawing_allowed',
  'cutr_profile_group',
  'cutr_profile_allowed',
  'cutr_paint_allowed',
  'cutr_material_code',
  'cutr_margin_top',
  'cutr_margin_right',
  'cutr_margin_left',
  'cutr_margin_bottom',
  'cutr_length_mm',
  'cutr_is_cost_sku',
  'cutr_has_texture_dir',
  'cutr_has_grain_dir',
]
