import { Scroller, ScrollerDots, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { RichText } from '@graphcommerce/graphcms-ui'
import {
  Button as NextButton,
  responsiveVal,
  iconArrowForward,
  IconSvg,
} from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import renderers from '../RichText/renderers'
import { PromotionSliderFragment } from './PromotionSlider.gql'

export function PromotionSlider(props: PromotionSliderFragment) {
  const { promotions = [] } = props

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'primary.contrastText',
        width: '50%',
        height: '50%',
      }}
    >
      {promotions.length ? (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            width: responsiveVal(25, 360),
            height: responsiveVal(25, 360),
            '@media(max-width: 767px)': {
              width: responsiveVal(175, 185, 767),
              height: responsiveVal(175, 185, 767),
            },
          }}
        >
          <ScrollerProvider>
            <Scroller
              hideScrollbar
              sx={{
                width: '100%',
                height: '100%',
                gridAutoColumns: '100%',
                borderRadius: '50%',
                bgcolor: 'primary.main',
              }}
            >
              {promotions.map((promotion) => (
                <Box
                  key={promotion.url}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      textAlign: 'center',
                      overflow: 'hidden',
                      display: 'block',
                      marginX: 2,
                      [theme.breakpoints.down('md')]: {
                        '& h1, & h2, & h3, & h4, & h5': {
                          marginBottom: responsiveVal(8, 18),
                        },
                      },
                    })}
                  >
                    {promotion.description && (
                      <RichText
                        raw={promotion.description?.raw}
                        sxRenderer={{
                          ...renderers,
                          'heading-two': {
                            '@media(max-width: 767px)': {
                              fontSize: '14px',
                            },
                            '@media(min-width: 767px)': {
                              fontSize: responsiveVal(0, 32),
                            },
                            lineHeight: 1.433,
                            margin: '5px',
                          },
                        }}
                      />
                    )}
                    <NextButton
                      href={promotion.url}
                      target={promotion.openInNewWindow ? '_blank' : '_self'}
                      variant='pill'
                      color='primary'
                      size='large'
                      sx={(theme) => ({
                        [theme.breakpoints.up('sm')]: {
                          fontSize: responsiveVal(5, 24),
                        },
                        fontWeight: 400,
                        padding: 0,
                        [theme.breakpoints.down('md')]: {
                          bottom: 10,
                        },

                        textDecoration: 'underline',
                        background: 'none',
                        whiteSpace: 'nowrap',
                        boxShadow: 'none!important',
                        [theme.breakpoints.down('sm')]: {
                          fontSize: '12px',
                        },
                        '&:hover': {
                          textDecoration: 'underline',
                          backgroundColor: 'transparant',
                        },
                      })}
                      endIcon={
                        <Box
                          sx={(theme) => ({
                            borderRadius: '50%',
                            background: theme.palette.primary.contrastText,
                            marginLeft: responsiveVal(0, 8),
                            '& > svg': {
                              display: 'block',
                              stroke: theme.palette.primary.main,
                              strokeWidth: '3px',
                              padding: responsiveVal(0, 7),
                              width: responsiveVal(-5, 32),
                              height: responsiveVal(-5, 32),
                            },
                            [theme.breakpoints.down('sm')]: {
                              '& > svg': {
                                width: 19,
                                height: 19,
                                padding: '4px',
                              },
                            },
                          })}
                        >
                          <IconSvg size='small' src={iconArrowForward} />
                        </Box>
                      }
                    >
                      {promotion.title}
                    </NextButton>
                  </Box>
                </Box>
              ))}
            </Scroller>
            <ScrollerDots
              sx={(theme) => ({
                gap: 0,
                position: 'absolute',
                bottom: 15,
                left: '50%',
                transform: 'translateX(-50%)',
                [theme.breakpoints.down('sm')]: {
                  bottom: 10,
                },

                '& .ScrollerDot-dot': {
                  [theme.breakpoints.down('sm')]: {
                    width: 15,
                    height: 15,
                  },
                  '&:hover': {
                    background: 'none',
                  },
                },

                '& .ScrollerDot-circle': {
                  background: theme.palette.primary.contrastText,
                  [theme.breakpoints.down('sm')]: {
                    width: 5,
                    height: 5,
                  },
                },
              })}
            />
          </ScrollerProvider>
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            display: 'inline-grid',
            gridTemplateColumns: `repeat(2, ${responsiveVal(37, 76)})`,
            position: 'absolute',
            top: '19%',
            left: '61%',
            gap: responsiveVal(0, 5),
            [theme.breakpoints.down('md')]: {
              top: '15%',
              left: '57%',
            },
            [theme.breakpoints.down('sm')]: {
              top: '5%',
              left: '47%',
              gap: 8,
            },
            '&::before, &::after': {
              content: '""',
              width: '100%',
              height: 0,
              paddingTop: '100%',
              borderRadius: '50%',
            },
            '&::before': {
              background: theme.palette.primary.main,
              gridColumn: '2/3',
            },
            '&::after': {
              background: theme.palette.primary.contrastText,
              gridRow: 2,
            },
          })}
        />
      )}
    </Box>
  )
}
