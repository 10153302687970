import { Asset } from '@graphcommerce/graphcms-ui'
import { SpecialBanner, responsiveVal, breakpointVal } from '@graphcommerce/next-ui'
import { Box, Link } from '@mui/material'
import { RichTextHeadingStrongAlternateColor } from '../RichText/RichTextHeadingStrongAlternateColor'
import { RowSpecialBannerFragment } from './RowSpecialBanner.gql'

type RowSpecialBannerProps = RowSpecialBannerFragment

export function RowSpecialBanner(props: RowSpecialBannerProps) {
  const { copy, asset, topic, pageLinks } = props

  // Asset, like a brand logo, to be overlayed over the asset from RowSpecialBanner, hence the name "assetOverlayAsset"
  const assetOverlayAsset = pageLinks.map(
    (pageLink) =>
      pageLink.asset && (
        <Box
          key={pageLink.title}
          sx={{ position: 'absolute', bottom: '5%', left: '5%', width: '33%' }}
        >
          <a
            href={pageLink.url}
            target={pageLink.openInNewWindow ? '_blank' : '_self'}
            rel='noreferrer'
          >
            <Asset asset={pageLink.asset} />
          </a>
        </Box>
      ),
  )

  return (
    <SpecialBanner
      maxWidth='lg'
      sx={(theme) => ({
        '& .SpecialBanner-wrapper': {
          gap: 2,
          background: 'none',
        },
        '& .SpecialBanner-asset': {
          width: responsiveVal(250, 750),
          img: {
            width: responsiveVal(250, 750),
            borderRadius: { xs: 0 },
          },
          marginBottom: { md: 0 },
        },
        '& .SpecialBanner-topic': {
          color: `${theme.palette.text.primary} !important`,
          marginBottom: theme.spacings.xxs,
        },
        '& .SpecialBanner-links': {
          marginTop: theme.spacings.md,
        },
        '& .SpecialBanner-textContainer': {
          '& > *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6)': {
            lineHeight: 2,
            ...breakpointVal('fontSize', 14, 24, theme.breakpoints.values),
          },
          '& h2, & h3, & h4, & h5, & h6': {
            marginBottom: theme.spacings.md,
          },
          [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
          },
          '& p:last-of-type': {
            marginBottom: 0,
          },
        },
      })}
      topic={topic}
      asset={
        asset && (
          <Box sx={{ position: 'relative' }}>
            <Asset asset={asset} sizes='50vw' /> {assetOverlayAsset}
          </Box>
        )
      }
      pageLinks={pageLinks.map(({ url, title, openInNewWindow }) => (
        <Link
          underline='always'
          href={url}
          key={url}
          target={openInNewWindow ? '_blank' : '_self'}
          title={title}
          sx={(theme) => ({
            typography: { xs: 'body1', md: 'body1' },
            ...breakpointVal('fontSize', 14, 24, theme.breakpoints.values),
          })}
        >
          {title}
        </Link>
      ))}
    >
      <RichTextHeadingStrongAlternateColor {...copy} />
    </SpecialBanner>
  )
}
