import { Box, Container } from '@mui/material'
import { RowVideoFragment } from './RowVideo.gql'

type RowVideoProps = RowVideoFragment

export function RowVideo(props: RowVideoProps) {
  const { videoLink, playerWidth } = props

  return (
    <Container maxWidth='md'>
      <Box
        sx={(theme) => ({
          aspectRatio: '16/9',
          width: playerWidth ?? '100%',
          maxWidth: playerWidth,
          mx: 'auto',
          mb: theme.spacings.lg,
        })}
      >
        <iframe
          title={videoLink}
          src={videoLink}
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Container>
  )
}
