import {
  AddProductsToCartFab,
  ProductListItemRenderer,
  ProductListItemSkeleton,
  useFormAddProductsToCart,
  useProductLink,
} from '@graphcommerce/magento-product'
import { ProductListItemBundle } from '@graphcommerce/magento-product-bundle'
import { ProductListItemConfigurable } from '@graphcommerce/magento-product-configurable'
import { ProductListItemDownloadable } from '@graphcommerce/magento-product-downloadable'
import { ProductListItemGrouped } from '@graphcommerce/magento-product-grouped'
import { ProductListItemSimple } from '@graphcommerce/magento-product-simple'
import { ProductListItemVirtual } from '@graphcommerce/magento-product-virtual'
import { ProductReviewSummary } from '@graphcommerce/magento-review'
import { ProductWishlistChip } from '@graphcommerce/magento-wishlist'
import { Fab, iconChevronRight } from '@graphcommerce/next-ui'
import iconShoppingCart from '@graphcommerce/next-ui/icons/cart.svg'
import { Typography, TypographyProps } from '@mui/material'

const Subtitle = (props: TypographyProps) => (
  <Typography component='span' variant='caption' {...props} />
)

export const crosssellsRender: ProductListItemRenderer = {
  Skeleton: ProductListItemSkeleton,
  SimpleProduct: (props) => {
    const { sku, options, index } = props
    const { setValue } = useFormAddProductsToCart()
    return (
      <ProductListItemSimple
        {...props}
        sx={{
          '&:last-of-type': {
            scrollSnapAlign: 'end !important',
          },
        }}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        bottomRight={
          <AddProductsToCartFab
            sku={sku}
            index={index}
            icon={iconShoppingCart}
            color='primary'
            onClick={() => {
              if (
                options &&
                options[0]?.required &&
                options[0].__typename === 'CustomizablePackagingOption'
              )
                setValue(`cartItems.${index}.selected_options`, [
                  options?.[0]?.value?.[0]?.uid ?? '',
                ])
            }}
          />
        }
      />
    )
  },
  ConfigurableProduct: (props) => {
    const productLink = useProductLink(props)
    return (
      <ProductListItemConfigurable
        {...props}
        sx={{
          '&:last-of-type': {
            scrollSnapAlign: 'end !important',
          },
        }}
        aspectRatio={[1, 1]}
        swatchLocations={{
          topLeft: [],
          topRight: [], // ['size']
          bottomLeft: [],
          bottomRight: ['dominant_color'],
        }}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        bottomRight={<Fab href={productLink} icon={iconChevronRight} color='primary' />}
      />
    )
  },

  BundleProduct: (props) => (
    <ProductListItemBundle
      {...props}
      subTitle={<Subtitle>BY GC</Subtitle>}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  VirtualProduct: (props) => {
    const { sku } = props
    return (
      <ProductListItemVirtual
        {...props}
        subTitle={<Subtitle>BY GC</Subtitle>}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        bottomRight={<AddProductsToCartFab sku={sku} />}
      />
    )
  },
  DownloadableProduct: (props) => (
    <ProductListItemDownloadable
      {...props}
      subTitle={<Subtitle>BY GC</Subtitle>}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  GroupedProduct: (props) => (
    <ProductListItemGrouped
      {...props}
      subTitle={<Subtitle>BY GC</Subtitle>}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore GiftCardProduct is only available in Commerce
  // GiftCardProduct: (props) => (
  //   <ProductListItem {...props} subTitle={<Subtitle>BY GC</Subtitle>} aspectRatio={[1, 1]} />
  // ),
}
