import { SxRenderer } from '@graphcommerce/graphcms-ui/components/RichText/types'
import { breakpointVal } from '@graphcommerce/next-ui'
import { alpha } from '@mui/material'

const renderers: SxRenderer = {
  'bulleted-list': (theme) => ({
    my: theme.spacings.xxs,
    pl: theme.spacings.xxs,
    '& li': {
      pl: theme.spacings.sm,
    },
    '& li::marker': {
      content: '"•"',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  }),
  'numbered-list': (theme) => ({
    mb: theme.spacings.sm,
  }),
  paragraph: (theme) => ({
    mb: theme.spacings.sm,
  }),
  'block-quote': (theme) => ({
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    p: theme.spacings.md,
    ...breakpointVal('fontSize', 14, 24, theme.breakpoints.values),
    lineHeight: 2,
    fontStyle: 'italic',
  }),
  'heading-one': (theme) => ({
    mt: `${theme.spacings.xl} !important`,
    mb: theme.spacings.xxs,
  }),
  'heading-two': (theme) => ({
    mt: theme.spacings.md,
    mb: theme.spacings.xxs,
  }),
  'heading-three': (theme) => ({
    mt: theme.spacings.md,
    mb: theme.spacings.xxs,
  }),
  'heading-four': (theme) => ({
    mt: theme.spacings.md,
    mb: 1,
  }),
  table: (theme) => ({
    display: 'block',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    borderSpacing: '2px',
    borderCollapse: 'collapse',
    marginTop: theme.spacings.md,
    marginBottom: theme.spacings.sm,
    '& th': {
      '& p': {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.h5,
        textAlign: 'left',
      },
    },
    '& thead, tbody': {
      '& td, th': {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingLeft: '10px',
        whiteSpace: 'pre-wrap',
        minWidth: '200px',
        maxWidth: '300px',
        '& p': {
          marginTop: '10px',
          marginBottom: '10px',
        },
      },
    },
    '& thead': {
      '& th': {
        verticalAlign: 'bottom',
      },
      '& tr': {
        justifyContent: 'flex-start',
        border: {
          borderWidth: 0,
          borderBottomWidth: 1,
          borderBottomColor: theme.palette.divider,
          borderStyle: 'solid',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {},
        '&:not(:last-child)': {
          border: {
            borderWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.divider,
            borderStyle: 'solid',
          },
        },
      },
    },
  }),
}

export default renderers
