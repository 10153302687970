import { iconChevronRight, IconSvg } from '@graphcommerce/next-ui'
import { ListItemButtonProps, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'

export type ButtonLinkListItemProps = {
  url: string
  endIcon?: React.ReactNode
  openInNewWindow?: boolean
} & ListItemButtonProps<'a'>

export function ButtonLinkListItem(props: ButtonLinkListItemProps) {
  const {
    children,
    url,
    endIcon = <IconSvg src={iconChevronRight} />,
    openInNewWindow = false,
    ...ButtonLinkListItemProps
  } = props

  return (
    <ListItemButton
      href={url}
      {...ButtonLinkListItemProps}
      target={openInNewWindow ? '_blank' : '_self'}
      sx={(theme) => ({
        padding: `${theme.spacings.xxs} 0`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        justifyContent: 'space-between',
      })}
    >
      <ListItemText>{children}</ListItemText>
      <ListItemIcon>{endIcon}</ListItemIcon>
    </ListItemButton>
  )
}
