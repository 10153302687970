import { RichText, RichTextProps } from '@graphcommerce/graphcms-ui'
import renderers from './renderers'

export function RichTextHeadingStrongAlternateColor(props: RichTextProps) {
  const { sxRenderer, ...rest } = props
  return (
    <RichText
      {...rest}
      sxRenderer={{
        ...renderers,
        all: {
          whiteSpace: 'pre-wrap',
          '& > strong': { color: 'primary.main' },
          ...sxRenderer?.all,
        },
        'heading-one': {
          '& > strong': { color: 'primary.main' },
          ...sxRenderer?.['heading-one'],
        },
        'heading-two': {
          '& > strong': { color: 'primary.main' },
          ...sxRenderer?.['heading-two'],
        },
        'heading-three': {
          '& > strong': { color: 'primary.main' },
          ...sxRenderer?.['heading-three'],
        },
      }}
    />
  )
}
