/* eslint-disable react/destructuring-assignment */
import { ButtonLinkList, IconSvg, ButtonLinkListProps } from '@graphcommerce/next-ui'
import iconDownload from '@graphcommerce/next-ui/icons/download.svg'
import { ButtonLinkListItem } from './ButtonLinkListItem'
import { RowButtonLinkListFragment } from './RowButtonLinkList.gql'

export type RowButtonLinkListProps = RowButtonLinkListFragment & Partial<ButtonLinkListProps>

const RenderLinks = (link: RowButtonLinkListProps['links'][0]) => {
  switch (link.__typename) {
    case 'PageLink':
      return (
        <ButtonLinkListItem
          url={link.asset?.url ?? link.url}
          openInNewWindow={link.openInNewWindow ?? false}
          endIcon={link.asset?.url && <IconSvg src={iconDownload} />}
        >
          {link.PageLinkTitle}
        </ButtonLinkListItem>
      )
    case 'Page':
    default:
      return <ButtonLinkListItem url={`/${link.url}`}>{link.PageTitle ?? ''}</ButtonLinkListItem>
  }
}

export function RowButtonLinkList(props: RowButtonLinkListProps) {
  const { title, links, sx = [], ...etc } = props

  const isBig = links.some((link) => {
    switch (link.__typename) {
      case 'PageLink':
        return (link.PageLinkTitle?.length ?? 0) > 30
      case 'Page':
      default:
        return (link.PageTitle?.length ?? 0) > 30
    }
  })

  return (
    <ButtonLinkList
      {...etc}
      title={title}
      containsBigLinks={isBig}
      sx={[
        (theme) => ({
          mb: theme.spacings.lg,
          '& .ButtonLinkList-links .MuiButtonBase-root': {
            paddingX: theme.spacings.xs,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {links?.map((link) => (
        <RenderLinks key={link.id} {...link} />
      ))}
    </ButtonLinkList>
  )
}
