import { RichText } from '@graphcommerce/graphcms-ui'
import { Box, Button, useTheme } from '@mui/material'
import { HeroBanner } from '../../HeroBanner'
import { RowHeroBannerFragment } from '../../RowHeroBanner.gql'

type LongHeroProps = Omit<RowHeroBannerFragment, 'secondBanner' | 'promotions'>

export function LongHero(props: LongHeroProps) {
  const { copy, heroAsset, heroTitle, pageLinks } = props
  const bannerUrl = pageLinks?.[0].url

  const theme = useTheme()

  return (
    <HeroBanner
      bannerUrl={bannerUrl}
      pageLinks={pageLinks.map(({ url, title }) => (
        <Button
          LinkComponent='span'
          key={url}
          variant='pill'
          color='primary'
          size='large'
          sx={{ mb: theme.spacings.xxs, ml: theme.spacings.xxs }}
        >
          {title}
        </Button>
      ))}
      asset={heroAsset}
      sx={{
        textDecoration: 'none',
        mb: 0,
        '& .HeroBanner-wrapper': {
          borderRadius: 0,
        },
        '& .HeroBanner-copy': {
          maxWidth: '500px',
          backgroundColor: 'white',
          clipPath: {
            xs: 'ellipse(85% 100% at 8% 50%)',
            sm: 'unset',
            md: 'ellipse(92% 100% at 8% 50%)',
          },
          textAlign: 'left',
          justifyItems: 'flex-start',
          gap: theme.spacings.sm,
        },
      }}
    >
      <Box
        component='span'
        sx={{
          display: 'grid',
          maxWidth: { xs: '280px', sm: '360px' },
          pl: theme.spacings.xxs,
        }}
      >
        <RichText
          {...heroTitle}
          sxRenderer={{
            'heading-one': {
              mt: 1,
              mb: theme.spacings.xs,
              color: theme.palette.background.default,
              '& strong': {
                color: theme.palette.primary.main,
              },
            },
          }}
        />
        <RichText
          {...copy}
          sxRenderer={{
            paragraph: {
              typography: 'body1',
              fontWeight: 'bold',
              color: theme.palette.background.default,
            },
          }}
        />
      </Box>
    </HeroBanner>
  )
}
